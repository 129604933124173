/* ************************************************
BLOG
************************************************ */
section.case-item-nav {
    padding: 6rem 0 2rem 0;
}

section.case-item-list {
    padding: 6rem 0;

    article.case-item {
        margin-bottom: 1.5rem;
        overflow: hidden;
        
        &:hover {

            .case-item-summary {
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.075);
            }
        }

        .case-item-image {
            position: relative;
            overflow: hidden;

            &:hover {

                img {
                    transform: scale(1.1);
                }

                .overlay {
                    visibility: visible;
                    opacity: 1;
                    z-index: 10;

                    .overlay-content {
                        opacity: 1;
                        top: 42%;
                    }
                }
            }

            img {
                transition: 0.3s ease-in-out;
            }

            .overlay {
                visibility: hidden;
                opacity: 0;
                background: rgba(0, 0, 0, 0.85);
                text-align: center;
                transition: all .3s ease-in-out;

                .overlay-content {
                    opacity: 0;
                    position: absolute;
                    top: 46%;
                    width: 100%;
                    font-size: 1.125rem;
                    color: #fff;
                    text-align: center;
                    transition: 0.3s ease-in-out;
                    
                    .case-item-logo {
                        margin-bottom: 1rem;
                    }

                    i {
                        display: block;
                        font-size: 2rem;
                    }
                }
            }
        }

        .case-item-summary {
            position: absolute;
            bottom: 0;
            width: auto;
            padding: 2rem 3rem;
            transition: all .3s ease-in-out;

            .case-item-category {
                font-size: 0.75rem;
                text-transform: uppercase;
            }

            h3.case-item-title {
                margin-top: 1rem;
                text-transform: uppercase;
                font-weight: 700;
                color: #fff;
            }

            .case-item-text {
                padding-top: 1rem;
                font-size: 1.25rem;
                font-weight: 300;
                color: #fff;
                border-top: 1px solid rgba(255, 255, 255, 0.3);
            }
        }
    }
}




/* ************************************************
CASE ITEM
************************************************ */

body.caseitem {

    .page-content {

        > div:last-child {
            margin-bottom: 3rem;

            .align-items-center {

                &::after {
                    border-top: none;
                    border-bottom: none;
                }
            }
        }
    }

    .container {

        .align-items-center {
            padding-top: 4em;
            padding-bottom: 0;

            &::after {
                content: '';
                position: relative;
                display: block;
                width: 100%;
                height: 10px;
                border-top: 1px solid $border-light;
                border-bottom: 1px solid $border-light;
                margin-top: 4rem;
            }

            div[class^="col"] {
                padding: 1rem;
                text-align: center;
            }
        }

        @include media-breakpoint-up(md) {
            .align-items-center {
                padding-top: 6em;

                &::after {
                    margin-top: 6rem;
                }

                div[class^="col"] {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .align-items-center {
                padding-top: 8em;

                &::after {
                    margin-top: 8rem;
                }

                div[class^="col"] {
                    text-align: left;
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            .align-items-center {
                padding-top: 10em;

                &::after {
                    margin-top: 10rem;
                }
            }
        }
    }
}