// ***********************************
// COVER
// ***********************************

#cover {
    position: relative;
    background-color: #242628;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    margin: 0 -15px;
    padding: 0;
    overflow: hidden;

    .cover-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        color: #fff;
        text-align: center;

        .cover-caption {

            h1 {
                margin-bottom: 2rem;
                font-size: 1.75rem;
                font-weight: 700;
                text-transform: uppercase;
            }

            .lead {
                display: none;
            }

            @include media-breakpoint-up(md) {

                h1 {
                    font-size: 2.5rem;
                }

                .lead {
                    display: block;
                    margin: 2rem 0 3rem 0;
                    padding-bottom: 2rem;
                    font-size: 1rem;
                    line-height: 1.6;
                    text-transform: none;
                    color: #fff;
                }
            }

            @include media-breakpoint-up(lg) {

                h1 {
                    font-size: 2.5rem;
                }

                .lead {
                    margin: 2rem 0 3rem 0;
                    padding-bottom: 2rem;
                    font-size: 1rem;
                }
            }

            @include media-breakpoint-up(xl) {

                h1 {
                    font-size: 3rem;
                }
            }
        }
    }
}
