// ***********************************
// LIGHTSLIDER
// ***********************************
@import "../../../bower_components/lightslider/dist/css/lightslider";

// Custom styles
.lSSlideWrapper {

    &:hover {

        .lSAction {

            .lSPrev,
            .lSNext {
                opacity: 0.70;
            }

            .lSPrev {
                left: 0;
            }

            .lSNext {
                right: 0;
            }
        }
    }

    .lSAction {
        
        .lSPrev,
        .lSNext {
            background-image: none;
            background-color: #000;
            transition: all 0.2s ease-in-out;
            width: 40px;
            height: 100px;
            line-height: 100px;
            text-align: center;

            &:after {
                display: inline-block;
                font-family: 'Ionicons';
                font-size: 2.5rem;
                color: #fff;
            }

            &:hover {
                opacity: .85;
            }
        }

        .lSPrev {
            left: -40px;

            &:after {
                content: '\f3d2';
            }
        }

        .lSNext {
            right: -40px;

            &:after {
                content: '\f3d3';
            }
        }
    }
}



