// ***********************************
// HEADER
// ***********************************

/* HEADER */
header.header {
    background-color: $header-bg;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height;
    line-height: $header-height;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    z-index: 100;
    transition: $transition-cubic-bezier;

    .container {
        width: 100%;
        max-width: 1980px;
        padding: 0;
    }

    .header-logo {
        position: absolute;
        background: url('../images/logo-light2.svg') no-repeat;
        background-size: 140px $header-height;
        width: 140px;
        margin-left: 6rem;
        text-indent: -9999px;
        transition: $transition-cubic-bezier;
    }

    /* navigation */
    nav.navigation {
        float: right;
        margin-right: 6rem;

        span {
            display: none;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            > li {
                position: relative;
                display: inline-block;
                margin: 0 1.5rem;
                //line-height: $header-height;

                > a {
                    color: #fff;
                    font-size: 0.75rem;
                    font-weight: 600;
                    text-decoration: none;
                    text-transform: uppercase;

                    &.active {
                        color: $red;
                    }
                }

                &.social-link {

                    i {
                        font-size: 1rem;
                    }
                }

                &:hover {

                    > a {
                        color: $brand-primary;
                    }
                }

                &::after {
                    content: '';
                    background: #ccc;
                    position: absolute;
                    display: block;
                    top: 50%;
                    right: -1.65rem;
                    width: 1px;
                    height: $header-height / 4;
                    transform: translateY(-50%);
                }

                &:last-child {

                    &::after {
                        display: none;
                    }
                }

                &.has-dropdown {

                    > a {

                        &::after {
                            content: '\f107';
                            display: inline-block;
                            margin-left: .5rem;
                            font-family: 'FontAwesome';
                        }
                    }

                    ul.dropdown {
                        visibility: hidden;
                        opacity: 0;
                        background: $header-bg-fixed;
                        position: absolute;
                        min-width: 200px;
                        max-height: 0;
                        margin-left: -1.5rem;
                        margin-top: -1px;
                        list-style: none;
                        border-left: 1px solid #e3e3e3;
                        border-right: 1px solid #e3e3e3;
                        border-bottom: 1px solid #e3e3e3;
                        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.07);
                        transition: $transition-cubic-bezier;

                        > li {
                            display: block;
                            border-bottom: 1px solid #e3e3e3;
                            margin: 0;
                            padding: 0 1rem;
                            line-height: 50px;
                            transition: $transition-cubic-bezier;

                            &::after {
                                display: none;
                            }

                            &:hover {
                                background: #fff;

                                a {
                                    color: $brand-primary;
                                }
                            }

                            > a {
                                display: block;
                                color: $dark-text;
                                font-size: 0.875rem;
                                text-transform: none;
                                letter-spacing: normal;

                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }

                    &:hover {

                        ul.dropdown {
                            visibility: visible;
                            opacity: 1;
                            max-height: 400px;
                        }
                    }
                }
            }
        }
    }

    #search-button {
        position: absolute;
        right: 0;
        height: $header-height;
        width: 6rem;
        text-align: center;
        color: #fff;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        transition: $transition-cubic-bezier;

        &:before {
            font-family: "Ionicons";
            content: "\f4a4";
            font-size: 1.75rem;
            color: #fff;
        }

        &.active {
            background: #fff;

            &:before {
                content: "\f2d7";
                color: $dark-text;
            }
        }
    }

    &.fixed {
        background: $header-bg-fixed;
        top: 0;
        height: $header-height-fixed;
        line-height: $header-height-fixed;
        border-bottom: 1px solid $border-light;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.07);

        .header-logo {
            background: url('../images/logo-dark2.svg') no-repeat;
            background-size: 100px $header-height-fixed;
            width: 100px;
        }

        #search-button {

            &:before {
                color: $dark-text;
            }
        }

        nav.navigation {

            ul {

                > li {

                    > a {
                        color: $dark-text;
                        
                        &:hover,
                        &.active {
                            color: $red;
                        }

                    }
                }
            }
        }

        #search-button {
            height: $header-height-fixed;

            i {
                line-height: 70px;
                color: $dark-text;
            }
        }
    }
}



/* RESPONSIVE */
@include media-breakpoint-down(md) {
    .off-canvas-pusher,
    .site-container {
        height: 100%;
    }

    .off-canvas-pusher {
        transition: $transition-cubic-bezier;
        transform: translateX(0px);
    }

    header.header {
        background-color: $header-bg;
        background: #fff;
        height: 4rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        &.fixed {
            background: url('../images/logo-dark2.svg') no-repeat;
        }

        .header-logo {
            background: url('../images/logo-dark2.svg') no-repeat;
            background-size: 100px 4.125rem;
            width: 100px;
            height: 4.125rem;
            margin-left: 1rem;
            display: inline-block;
            text-indent: -9999px;
        }

        #search-button {
            display: none;
        }

        nav.off-canvas-navigation {
            position: fixed;
            width: $off-canvas-width;
            height: 100%;
            top: 0;
            right: 0;
            margin-right: 0;
            transform: translateX(-$off-canvas-width * -1);
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            z-index: 9999;

            span {
                display: block;
                background: darken($off-canvas-bg, 4);
                height: $topbar-height;
                line-height: $topbar-height;
                padding: 0 1.5rem;
                text-transform: uppercase;
                font-weight: 700;
                text-indent: -9999px;
            }

            ul {
                margin-top: 4rem;
                padding: 0 0 1.5rem 0;
                background: $off-canvas-bg;

                > li {
                    display: block;
                    line-height: normal;
                    margin: 0;
                    padding: 0;
                    border-bottom: 1px solid $border-light;

                    &:hover {

                        > a {
                            color: $dark-text;
                        }
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    &::after {
                        display: none;
                    }

                    a {
                        display: block;
                        padding: 0 1rem 0 2rem;
                        font-size: 1rem;
                        line-height: 3rem;
                        color: $dark-text;

                        &.active {
                           color: $red;

                            &:after {
                                content: '';
                                position: absolute;
                                background: $red;
                                width: 4px;
                                height: 100%;
                                left: 0;
                            } 
                           
                        }
                    }
                }

                li.has-dropdown {
                    display: block;
                    line-height: normal;

                    &::after {
                        display: none;
                    }

                    a {
                        &::after {
                            display: none;
                        }
                    }

                    ul.dropdown {
                        background: none;
                        position: relative;
                        visibility: visible;
                        opacity: 1;
                        max-height: 400px;
                        margin: 0;
                        padding: 0 0 0 10px;
                        border: none;
                        box-shadow: none;

                        li {
                            padding: 0;
                            border-bottom: none;

                            &:hover {
                                background: none;

                                > a {
                                    color: $dark-text;
                                }
                            }

                            a {
                                color: $dark-text;
                                line-height: normal;
                                padding: 3px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    #off-canvas-open  {
        position: relative;
        display: block;
        float: right;
        width: 50px;
        height: 4rem;
        margin-right: 1rem;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 25px;
            height: 0;
            top: 17px;
            left: 15px;
            box-shadow: 0 8px 0 1px #333, 0 16px 0 1px #333, 0 24px 0 1px #333;
        }
    }

    #off-canvas-close {
        position: relative;
        display: block;
        float: right;
        width: 50px;
        height: 4rem;
        margin-right: 0;
        cursor: pointer;

        &::before, &::after {
            content: '';
            position: absolute;
            height: 2px;
            width: 50%;
            top: 50%;
            left: 0;
            margin-top: -1px;
            background: #333;
          }

          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
    }

    .off-canvas {
        overflow: hidden;

        .off-canvas-pusher {
            transform: translateX(-$off-canvas-width);
        }

        #off-canvas-overlay {
            background-color: rgba(0, 0, 0, 0.75);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            z-index: 100;
        }
    }
}