// ***********************************
// LIGHTGALLERY
// ***********************************
//@import "../../../bower_components/lightgallery/src/sass/lightgallery.scss";

// custom styles
.lg-outer .lg-thumb-item {
    border-radius: 0;
}

.lg-outer .lg-thumb-item.active, 
.lg-outer .lg-thumb-item:hover {
    border-color: #e39b21;
}