﻿/* ************************************************
BLOG
************************************************ */

section.blog-post-info {
    background: #fafafa;
    padding: 1rem 0;
    border-bottom: 1px solid $border-light;

    p {
        margin-bottom: 0;
        font-size: 0.8275rem;
    }

    img {
        background: #fff;
        width: 75px;
        height: 75px;
        padding: 6px;
        border-radius: 75px;
        border: 1px solid $border-light;
    }
}

section.blog-post {
    padding: 6rem 0;

    article.blog-post {

        p {
            margin-bottom: 2rem;
            font-size: 1rem;
            line-height: 24px;
            color: #555657;
        }

        img {
            margin-bottom: 2rem;
        }
    }

    .tags {

        a {
            display: inline-block;
            padding: 5px 10px;
            margin: 0 2px 0.5rem 0;
            color: #767778;
            font-size: 0.75rem;
            font-weight: 500;
            border: 1px solid #ccc;
            border-radius: 3px;

            &:hover {
                color: #353637;
                text-decoration: none;
                border-color: #666;
            }
        }
    }
}