﻿/* ************************************************
ICON BOXES
************************************************ */

// Services section
.icon-boxes {
   
    .icon-box {
        display: block;
        text-align: center;
        margin-bottom: 3rem;

        .media-left {
            padding-right: 0;
        }

        img {
            max-width: 80px;
            padding-right: 0;
            margin: 2rem auto;
        }

        h6.media-heading {
            font-weight: 600;
        }

        p {
            margin-top: 1rem;
            font-size: 1rem;
            color: $silver-text;
        }

        a {
            font-size: 0.75rem;
            font-weight: 600;

            i {
                margin-left: 5px;
            }

            &:hover {

                i {
                    padding-left: 0.5rem;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .icon-box {
            margin-bottom: 4rem;
        }
    }

    @include media-breakpoint-up(lg) {
        .icon-box {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 0;

        .icon-box {
            display: flex;
            text-align: left;

            &:hover {

                img {
                    transform: rotate(15deg) scale(1.3);
                    /*filter: grayscale(0);*/
                }
            }

            .media-left {
                padding-right: 0;
            }

            img {
                padding-right: 2rem;
	            /*filter: grayscale(100%);*/
                transform: rotate(0) scale(1);
                transition: all 0.2s linear;
            }
        }
    }
}