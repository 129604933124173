// ***********************************
// IMPORTS
// Import Bootstrap
// ***********************************

// Custom Bootstrap 4 variables
@import "vendor/bootstrap-variables-override";

/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "../../bower_components/bootstrap/scss/variables";
@import "../../bower_components/bootstrap/scss/mixins";
@import "../../bower_components/bootstrap/scss/custom";

// Reset and dependencies
@import "../../bower_components/bootstrap/scss/normalize";
@import "../../bower_components/bootstrap/scss/print";

// Core CSS
@import "../../bower_components/bootstrap/scss/reboot";
@import "../../bower_components/bootstrap/scss/type";
@import "../../bower_components/bootstrap/scss/images";
@import "../../bower_components/bootstrap/scss/code";
@import "../../bower_components/bootstrap/scss/grid";
@import "../../bower_components/bootstrap/scss/tables";
@import "../../bower_components/bootstrap/scss/forms";
@import "../../bower_components/bootstrap/scss/buttons";

// Components
@import "../../bower_components/bootstrap/scss/transitions";
@import "../../bower_components/bootstrap/scss/dropdown";
@import "../../bower_components/bootstrap/scss/button-group";
@import "../../bower_components/bootstrap/scss/input-group";
@import "../../bower_components/bootstrap/scss/custom-forms";
@import "../../bower_components/bootstrap/scss/nav";
@import "../../bower_components/bootstrap/scss/navbar";
@import "../../bower_components/bootstrap/scss/card";
@import "../../bower_components/bootstrap/scss/breadcrumb";
@import "../../bower_components/bootstrap/scss/pagination";
@import "../../bower_components/bootstrap/scss/badge";
@import "../../bower_components/bootstrap/scss/jumbotron";
@import "../../bower_components/bootstrap/scss/alert";
@import "../../bower_components/bootstrap/scss/progress";
@import "../../bower_components/bootstrap/scss/media";
@import "../../bower_components/bootstrap/scss/list-group";
@import "../../bower_components/bootstrap/scss/responsive-embed";
@import "../../bower_components/bootstrap/scss/close";

// Components w/ JavaScript
@import "../../bower_components/bootstrap/scss/modal";
@import "../../bower_components/bootstrap/scss/tooltip";
@import "../../bower_components/bootstrap/scss/popover";
@import "../../bower_components/bootstrap/scss/carousel";

// Utility classes
@import "../../bower_components/bootstrap/scss/utilities";


/*!
 * Site specific styling and plugins
 */

// Base styles import
@import "base/variables";
@import "base/buttons";
@import "base/forms";
@import "base/global";
@import "base/typography";

// Layout styles import
@import "layout/footer";
@import "layout/header";

// Components styles import
@import "components/contact-form";
@import "components/cover";
@import "components/hamburger";
@import "components/heros";
@import "components/icon-boxes";
@import "components/intro";
@import "components/nav";
@import "components/pagination";
@import "components/search-overlay";
@import "components/showcase-item";

// Page specific styles import
@import "pages/basic-page";
@import "pages/blog";
@import "pages/blog-post";
@import "pages/case";
@import "pages/contact";
@import "pages/home";
@import "pages/services";

// Vendor styles import
@import "vendor/lightslider";
@import "vendor/lightgallery";
