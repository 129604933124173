﻿

.contact-form {
    background: #fff;
    padding: 4rem 0;

    .contact-form-submit {
        margin: 2rem 0;
        
        i {
            margin-left: 5px;
        }
    }
}


.employees {
    background: #222;
    padding: 0;

    .col {
        padding: 0;
    }
    .employee {
        position: relative;
        overflow: hidden;
        /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);*/

        .overlay {
            opacity: 0;
            position: absolute;
            background: rgba(0, 0, 0, 0.8);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding-top: 5rem;
            transition: all 0.3s ease-in-out;
            z-index: 10;

            p {
                opacity: 0;
                margin-bottom: 0;
                padding: 0 2rem;
                text-align: center;
                font-size: 0.8275rem;
                font-weight: 300;
                color: #fff;
                transition: all 0.3s ease-in-out;

                i {
                    margin-right: 1rem;
                    font-size: 1.5rem;
                    color: #fff;
                    vertical-align: middle;
                }
            }

            a {
                color: #fff;
                text-decoration: underline;
            }
        }

        img {
            transition: all 0.3s ease-in-out;
        }

        &:hover {

            .overlay {
                opacity: 1;
                padding-top: 6rem;

                p {
                    opacity: 1;
                }
            }

            img {
                transform: scale(1.1);
            }
        }

        .employee-info {
            position: absolute;
            background: rgba(0, 0, 0, 0.8);
            height: auto;
            bottom: 0;
            left: 0;
            left: 0;
            right: 0;
            padding: 15px;
            text-align: center;
            color: #fff;
            z-index: 20;

            .name {
                font-size: 15px;
                font-weight: 600;
            }

            .title {
                font-size: 14px;
            }

            .social-media {
                
                a {
                    padding: 0 5px;

                    i {
                        font-size: 1.5rem;
                        color: #fff;
                    }
                }
            }
        }
    }
}

#google-maps { 
    width: 100%; 
    height: 600px; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0;
}

/*style the box*/  
    .gm-style .gm-style-iw {
        display: block !important;
        top: 0 !important;
        left: 0 !important;
        width: 320px!important;
        height: auto!important;
        padding: 20px;
        text-align: center;
    }    
     
    /*style the p tag*/
    .gm-style .gm-style-iw #google-popup p {
        
    }
         
     
    /*style the arrow*/
    .gm-style div div div div div div div div {
        padding: 0;
        margin: 0;
        padding: 0;
        top: 0;
        color: #353637;
        font-size: 16px;
    }
        
    /*style the link*/
    .gm-style div div div div div div div div a {
        color: $red;
        font-weight: bold;
    }