﻿.nav {
    padding-bottom: 4rem;
    text-align: center;

    button.btn {
        margin: 0 0.5rem;
        padding: .4rem 0.8rem;
        font-size: 0.75rem;
        text-transform: uppercase;
        color: #333;

        &:hover,
        &:focus {
            text-decoration: none;
            color: #999;
        }

        &.active {
            background: none;
            padding: .4rem 0.8rem;
            font-weight: 600;
            color: #333;
            border: 1px solid #333;
            border-radius: 2rem;

            &:hover {
                color: #333;
            }
        }
    }
}