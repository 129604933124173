/* ************************************************
VARIABELS
************************************************ */

//  Table of Contents:
//
//  1. Fonts
//  2. Colors
//  3. Header
//  4. Off-canvas
//  5. Footer
//  6. Transitions


//  1. Fonts
$font-poppins: 'Poppins', sans-serif;
$font-hind: 'Hind', sans-serif;

//  2. Colors
$red: #D24322;
$blue: #0cb4ce;
$green: #00ff21;

$dark-text: #353739;
$silver-text: #656769;
$light-text: #959799;

$border-light: #e3e3e3;
$border-dark: #999;

//  3. Header
$header-bg: rgba(0, 0, 0, 0.097);
$header-bg-fixed: rgba(255, 255, 255, 0.98);
$header-height: 6rem;
$header-height-fixed: 4.125rem;

//  4. Off-canvas
$off-canvas-width: 250px;
$off-canvas-bg: #fff;

$topbar-height: 2.8125rem;

//  5. Footer
$footer-bg: #131517;

// 6. Transitions
$transition-cubic-bezier: 0.3s cubic-bezier(0.3, 1, 0.5, 1);
