// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.


// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navbar
// Navs
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code

@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// Replace `$search` with `$replace` in `$string`
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin _assert-starts-at-zero($map) {
  $values: map-values($map);
  $first-value: nth($values, 1);
  @if $first-value != 0 {
    @warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
  }
}


// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.
//
$brand-primary:                #D24322 !default;
$brand-success:                #0cb4ce !default;


//
// // Options
// //
// // Quickly modify global styling by enabling or disabling optional features.
//


//
// // Spacing
// //
// // Control the default styling of most Bootstrap elements by modifying these
// // variables. Mostly focused on spacing.
// // You can add more entries to the $spacers map, should you need more variation.
//


//
// // Body
// //
// // Settings for the `<body>` element.
//


//
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1360px/*,
  xxxl: 1600px*/
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px,
  xxl: 1440px/*,
  xxxl: 1680px*/
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");


// 
// // Grid columns
//


//
// // Typography
// //
// // Font, line-height, and color for body text, headings, and more.
//
$lead-font-size:   1.125rem !default;
$mark-padding: .1em .4em !default;


//
// // Components
// //
// // Define common padding and border radius sizes and more.
//
$border-radius:          2.5rem !default;
$border-radius-lg:       3rem !default;
$border-radius-sm:       2rem !default;


//
// // Tables
// //
// // Customizes the `.table` component with basic values, each used across all table variations.
//


//
// // Buttons
// //
// // For each of Bootstrap's buttons, define text, background and border color.
//
$btn-padding-x:                  3rem !default;
$btn-padding-y:                  1rem !default;


//
// // Forms
//
$input-border-radius:               0 !default;
$input-border-radius-lg:            0 !default;
$input-border-radius-sm:            0 !default;


//
// // Form validation icons
//


//
// // Dropdowns
// //
// // Dropdown menu container and contents.
//


//
// // Z-index master list
// //
// // Warning: Avoid customizing these values. They're used for a bird's eye view
// // of components dependent on the z-axis and are designed to all work together.
//


//
// // Navbar
//


//
// // Navs
//


//
// // Pagination
//


//
// // Jumbotron
//


//
// // Form states and alerts
// //
// // Define colors for form feedback states and, by default, alerts.
//
$mark-bg:                           #eaecef !default;


//
// // Cards
//
$card-border-radius:          0 !default;


//
// // Tooltips
//


//
// // Popovers
//


//
// // Tags
//


//
// // Modals
//


//
// // Alerts
// //
// // Define alert colors, border radius, and padding.
//


//
// // Progress bars
//


//
// // List group
//


//
// // Image thumbnails
//


//
// // Figures
//


//
// // Breadcrumbs
//

//
//
// // Media objects
//


//
// // Carousel
//


//
// // Close
//


//
// // Code
//

