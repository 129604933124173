﻿.pagination {
    padding-top: 4rem;

    li {

        &:hover {
            background: transparent;
        }

        a {
            width: 35px;
            height: 35px;
            padding: 0;
            margin-left: 0;
            color: #333;
            line-height: 35px;
            border: none;

            &:hover {
                background: none;
                color: #999;
                text-decoration: none;
            }

            &.active {
                font-weight: 600;
                border: 1px solid #333;
                border-radius: 35px;

                &:hover {
                    color: #333;
                }
            }

            &.previous {
                margin-right: 4rem;
            }

            &.next {
                margin-left: 4rem;
            }

            i {
                font-size: 1.5rem;
            }
        }
    }
}