﻿/* ************************************************
BLOG
************************************************ */
section.blog-post-nav {
    padding: 6rem 0 2rem 0;
}

section.blog-post-list {
    padding-bottom: 6rem;

    article.blog-post {
        margin-bottom: 1.5rem;
        overflow: hidden;
        
        &:hover {

            .blog-post-summary {
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.095);
            }
        }

        .blog-post-image {
            position: relative;
            overflow: hidden;

            &:hover {

                img {
                    transform: scale(1.1);
                }

                .overlay {
                    visibility: visible;
                    opacity: 1;
                    z-index: 10;

                    .overlay-content {
                        opacity: 1;
                        top: 42%;
                    }
                }
            }

            img {
                transition: 0.3s ease-in-out;
            }

            .overlay {
                visibility: hidden;
                opacity: 0;
                background: rgba(0, 0, 0, 0.85);
                text-align: center;
                transition: all .3s ease-in-out;

                .overlay-content {
                    opacity: 0;
                    position: absolute;
                    top: 46%;
                    width: 100%;
                    font-size: .875rem;
                    color: #fff;
                    text-align: center;
                    transition: 0.3s ease-in-out;

                    i {
                        display: block;
                        font-size: 1.5rem;
                    }
                }
            }
        }

        .blog-post-summary {
            padding: 2rem 3rem;
            border: 1px solid $border-light;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
            transition: all .3s ease-in-out;

            a {
                color: $dark-text;

                &:hover {
                    color: $light-text;
                    text-decoration: none;
                }
            }

            .blog-post-category {
                font-size: 0.75rem;
                text-transform: uppercase;
            }

            h6.blog-post-title {
                min-height: 35px;
                margin-top: 1rem;
                font-size: 1rem;
                text-transform: uppercase;
                font-weight: 600;
            }

            .blog-post-text {
                min-height: 60px;
                padding-bottom: 1rem;
                font-size: 0.875rem;
                border-bottom: 1px solid $border-light;
            }

            .blog-post-author {
                font-size: 0.75rem;
                text-transform: uppercase;

                img {
                    margin-right: 5px;
                }
            }
        }
    }
}

section.blog-post-pagination {
    padding: 2rem 0 6rem 0;
}