﻿/* ************************************************
CONTACT FORM STYLES
************************************************ */

section.contact {

    #contact-form {

        .contact-form-success {
            padding: 2rem 0 4rem 0;
            text-align: center;
            transition: all .5s ease-in-out;
            
            i {
                font-size: 6rem;
            }
        }
    }
}