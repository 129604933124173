﻿// ====================================================================
// BUTTONS
// ====================================================================

button:focus {outline:0!important;}

.btn-red,
.btn-dark,
.btn-light {
    position: relative;
    display: inline-block;
    padding: 1rem 2rem;
    font-family: $font-hind;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border-radius: 3px;
    transition: $transition-cubic-bezier;
    overflow: hidden;

     &:after {
        content: "";
        background: rgba(200,200,200,0.4);
        display: block;
        position: absolute;
        border-radius: 50%;
        padding-top: 0;
        padding-left: 0;
        margin-top: 0;
        margin-left: 0;
        opacity: 1;
        transition: 0s;
    }
    
    &:hover {

        &:after {
            padding-top: 240%;
            padding-left: 240%;
            margin-top: -120%;
            margin-left: -120%;
            opacity: 0;
            transition: all 1s;

            
        }
    }
}

.btn-red {
    background: $red;
    color: #fff;
    border: 1px solid $red;

    &:hover,
    &:active,
    &:focus {
        background: darken($red, 8);
        color: #fff;
        text-decoration: none;
        box-shadow: none;
    }
}

.btn-dark {
    background: transparent;
    color: $dark-text;
    border: 1px solid $dark-text;

    &:hover,
    &:active,
    &:focus {
        color: $dark-text;
        text-decoration: none;
        border-color: $dark-text;
        box-shadow: none;
    }
}

.btn-light {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;

    &:hover,
    &:active,
    &:focus {
        color: #fff;
        text-decoration: none;
        border-color: #fff;
        box-shadow: none;
    }
}

.btn-large {
    padding: 1rem 2rem;
    font-size: .75rem;
    font-weight: 700;

    @include media-breakpoint-up(md) {
        padding: 1.25rem 2.5rem;
        font-size: .875rem;
        font-weight: 700;
    }

    @include media-breakpoint-up(lg) {
        padding: 1.5rem 3rem;
        font-size: 1rem;
        font-weight: 700;
    }
}