// ***********************************
// FOOTER
// ***********************************

footer.footer {
    background: $footer-bg;
    padding: 5rem 0;
    color: rgba(255, 255, 255, 0.4);

    div[class^="col"] {
        padding: 0 3rem;
        border-right: 1px solid #333;

        &:last-child {
            border-right: none;
        }

        @include media-breakpoint-down(sm) {
            border-right: none;
            border-bottom: 1px solid #333;
            padding-bottom: 2rem;
            margin-bottom: 2rem;

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    h3 {
        margin-bottom: 1.5rem;
        color: #fff;
        font-size: 0.875rem;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    p {
        color: #fff;
        font-size: 0.75rem;
        font-weight: 300;
        line-height: 2;
    }

    a {
        color: #fff;
        font-size: 0.75rem;
    }

    .post-date {
        display: block;
        font-size: 0.75rem;
        font-weight: 300;
    }

    .tag-cloud {

        a {
            display: inline-block;
            padding: 5px 10px;
            margin: 0 2px 0.5rem 0;
            color: #fff;
            font-size: 0.75rem;
            border: 1px solid #fff;
            border-radius: 3px;

            &:hover {
                color: rgba(255, 255, 255, 0.5);
                text-decoration: none;
                border-color: rgba(255, 255, 255, 0.5);
            }
        }
    }
}
