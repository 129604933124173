// ***********************************
// PROMO
// ***********************************

// Promo
.intro {
    padding: 0 2rem;

    h1 {
        font-family: $font-poppins;
    }

    .lead {
        text-transform: none;
        line-height: 1.7;
    }

    @include media-breakpoint-up(md) {
        padding: 0 8rem;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 10rem;
    }

    @include media-breakpoint-up(xl) {
        padding: 0 12rem;
    }
}
