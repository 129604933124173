// ***********************************
// TYPOGRAPHY
// ***********************************

// Google fonts
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url('https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700');

// Icon fonts libraries
@import "../../../bower_components/font-awesome/scss/font-awesome";
@import "../../../bower_components/Ionicons/scss/ionicons";

$font-size-h1: 3.052rem !default;
$font-size-h2: 2.441rem !default;
$font-size-h3: 1.953rem !default;
$font-size-h4: 1.563rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;
//
// $display1-size: 6rem !default;
// $display2-size: 5.5rem !default;
// $display3-size: 4.5rem !default;
// $display4-size: 3.5rem !default;

h1 { font-size: $font-size-h1 * 0.7; }
h2 { font-size: $font-size-h2 * 0.7; }
h3 { font-size: $font-size-h3 * 0.7; }
h4 { font-size: $font-size-h4 * 0.7; }
h5 { font-size: $font-size-h5 * 0.7; }
h6 { font-size: $font-size-h6 * 0.7; }


@include media-breakpoint-up(sm) {
    h1 { font-size: $font-size-h1 * 0.8; }
    h2 { font-size: $font-size-h2 * 0.8; }
    h3 { font-size: $font-size-h3 * 0.8; }
    h4 { font-size: $font-size-h4 * 0.8; }
    h5 { font-size: $font-size-h5 * 0.8; }
    h6 { font-size: $font-size-h6 * 0.8; }
}

@include media-breakpoint-up(md) {
    h1 { font-size: $font-size-h1 * 0.9; }
    h2 { font-size: $font-size-h2 * 0.9; }
    h3 { font-size: $font-size-h3 * 0.9; }
    h4 { font-size: $font-size-h4 * 0.9; }
    h5 { font-size: $font-size-h5 * 0.9; }
    h6 { font-size: $font-size-h6 * 0.9; }
}

@include media-breakpoint-up(lg) {
    h1 { font-size: $font-size-h1 * 1.0; }
    h2 { font-size: $font-size-h2 * 1.0; }
    h3 { font-size: $font-size-h3 * 1.0; }
    h4 { font-size: $font-size-h4 * 1.0; }
    h5 { font-size: $font-size-h5 * 1.0; }
    h6 { font-size: $font-size-h6 * 1.0; }
}

@include media-breakpoint-up(xl) {
    h1 { font-size: $font-size-h1 * 1.1; }
    h2 { font-size: $font-size-h2 * 1.1; }
    h3 { font-size: $font-size-h3 * 1.1; }
    h4 { font-size: $font-size-h4 * 1.1; }
    h5 { font-size: $font-size-h5 * 1.1; }
    h6 { font-size: $font-size-h6 * 1.1; }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-poppins;
}

p {
    font-family: $font-hind;
    font-size: 1rem;
    line-height: 1.7;
    color: #545658;
}

.light {
    font-weight: 300;
}

.regular {
    font-weight: 400;
}

.medium {
    font-weight: 500;
}

.semi-bold {
    font-weight: 600;
}

.bold {
    font-weight: 700;
}

strong,
b {
    font-weight: 600;
}

a {
    color: $dark-text;

    &:hover {
        color: $light-text;
        text-decoration: none;
    }
}

.blockquote {
    margin-bottom: 2rem;
    padding: 1.5rem;
    font-size: 0.875rem;
    color: #858789;
    border-left: 3px solid $border-light;
}

pre {
    background: #fafafa;
    padding: 1rem 2rem 0rem 2rem;
    margin-bottom: 2rem;
    border-left: 3px solid $blue;
}

.lead {
    padding-top: 1rem!important;
    font-family: $font-poppins;
    font-weight: 400;
    line-height: 1;
    color: #777777;
}

// Typography styling
.left-underline,
.center-underline,
.right-underline {
    display: block;
    text-align: left;

    &::after {
        position: relative;
        display: block;
        background: $blue;
        content: '';
        width: 150px;
        height: 3px;
        margin: 1.25rem auto 1.25rem 0;
    }
}

.center-underline {
    text-align: center;

    &::after {
        margin: 1.25rem auto 1.25rem;
    }
}

.right-underline {
    text-align: right;

    &::after {
        margin: 1.25rem 0 1.25rem auto;
    }
}

@include media-breakpoint-down(md) {
    .left-underline,
    .center-underline,
    .right-underline {
        text-align: center;

        &::after {
            margin: 1.25rem auto 1.25rem;
        }
    }
}