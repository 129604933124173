// ***********************************
// COVER
// ***********************************

#hero {
    position: relative;
    background-color: #242628;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 350px;
    margin: 0 -15px;
    padding: 0;

    .hero-content {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 350px;
        color: #fff;
        text-align: center;

        .hero-caption {
            margin-top: $header-height;

            h1 {
                margin-bottom: 2rem;
                font-size: 1.75rem;
                font-weight: 700;
                text-transform: uppercase;
            }

            .lead {
                margin: 1rem 0;
                font-size: 0.875rem;
                font-weight: 300;
                text-transform: none;
                line-height: 1.7;
                color: #fff;
            }

            .btn {
                background: #fff;
                color: #353739;
                padding: 1rem 3rem;
                border-radius: 3rem;
                border-width: 2px;
                border-color: #fff;
                font-size: 0.875rem;
                font-weight: 400;

                &:hover {
                    background: transparent;
                    border-color: #fff;
                    color: #fff;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        height: 400px;

        .hero-content {
            height: 400px;

            h1 {
                font-size: 2.5rem;
            }

            .lead {
                display: block;
                margin: 2rem 0 3rem 0;
                padding-bottom: 2rem;
                font-size: 1rem;
                line-height: 1.6;
                text-transform: none;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        height: 450px;

        .hero-content {
            height: 450px;

            h1 {
                font-size: 2.5rem;
            }

            .lead {
                margin: 2rem 0 3rem 0;
                padding-bottom: 2rem;
                font-size: 1.125rem;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        height: 500px;

        .hero-content {
            height: 500px;

            .hero-caption {
                max-width: 1100px;
                
                h1 {
                    font-size: 3rem;
                }

                .lead {
                    font-size: 1.25rem;
                }
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        height: 600px;

        .hero-content {
            height: 600px;

            .hero-caption {
                max-width: 1100px;
            }
        }
    }
}
