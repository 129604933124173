// ***********************************
// HOME PAGE
// ***********************************

// Services section
section.intro {
    background: #fff;
    border-bottom: 1px solid #f1f1f1;
}

// Services section
section.services {
    background: #fff;
    border-bottom: 1px solid #f1f1f1;
}

// Services section
section.showcase {
    background: #fafbfc;
    max-height: 475px;
    border-bottom: 1px solid #f1f1f1;
    overflow: hidden;

    .container-fluid {
        padding: 0;
    }
}

// Services section
section.contact-form {
    background: #f9f7f5;
}