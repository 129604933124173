﻿/* ************************************************
SERVICES
************************************************ */

body.services {

    .page-content {

        > div:last-child {
            margin-bottom: 3rem;

            .align-items-center {

                &::after {
                    border-top: none;
                    border-bottom: none;
                }
            }
        }
    }

    .container {

        .align-items-center {

            /*&::after {
                content: '';
                position: relative;
                display: block;
                width: 100%;
                height: 10px;
                border-top: 1px solid $border-light;
                border-bottom: 1px solid $border-light;
                margin-top: 4rem;
            }*/

            div[class^="col"] {
                padding: 1rem;
                text-align: center;
            }
        }

        @include media-breakpoint-up(md) {
            .align-items-center {

                div[class^="col"] {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .align-items-center {

                div[class^="col"] {
                    text-align: left;
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }
        }
    }
}