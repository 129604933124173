﻿// ***********************************
// SEARCH OVERLAY
// ***********************************

.search-container {
    overflow-y: hidden;
    position: fixed;
    background: rgba(255, 255, 255, 1);
    top: $header-height;
    width: 100%;
    max-height: 0;
    transition-property: all;
	transition-duration: .5s;
	transition-timing-function: cubic-bezier(0.3, 1, 0.5, 1);
    z-index: 9999;


    &.active {
        max-height: 800px;
        border-bottom: 1px solid $border-light;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.07);
    }

    &.fixed {
        top: $header-height-fixed;
    }

    .search-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        form {
            width: 40%;
            padding: 6rem 0;
            overflow: hidden;

            input#SearchTerm {
                background: transparent;
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                font-family: $font-poppins;
                font-size: 3rem;
                color: $dark-text;
                border: none;
                border-bottom: 2px solid $border-light;

                &:focus {
                    box-shadow: none;
                }

                &::-webkit-input-placeholder {
                    font-size: 3rem;
                    font-weight: 300;
                    color: #000;
                }

                &:-moz-placeholder { /* Firefox 18- */
                    font-size: 3rem;
                    font-weight: 300;
                    color: #000;
                }

                &::-moz-placeholder { /* Firefox 19+ */
                    font-size: 3rem;
                    font-weight: 300;
                    color: #000;
                }

                &:-ms-input-placeholder {
                    font-size: 3rem;
                    font-weight: 300;
                    color: #000;
                }

		        // Remove clear input icon on IE
		        &::-ms-clear {
		            display: none;
		        }
            }

            button#submit-button {
                display: none;
            }

            #search-results {
                
                p.results {
                    margin-top: 1rem;
                    font-family: $font-poppins;
                    font-size: 1rem;
                }
                
                .result-item {
                    padding: 1rem;
                    border-top: 1px solid $border-light;

                    a {
                        font-size: 1.5rem;
                    }

                    p {
                            
                    }
                }
            }
        }
    }
}