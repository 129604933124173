﻿/* ************************************************
SHOWCASE ITEM
************************************************ */

.showcase-item {
    position: relative;
    font-family: $font-poppins;
    overflow: hidden;

    &:hover {

        img {
            transform: scale(1.1);
        }

        .overlay {
            visibility: visible;
            opacity: 1;
            z-index: 10;

            .overlay-content {
                opacity: 1;
                top: 42%;
            }
        }
    }

    img {
        transition: 0.3s ease-in-out;
    }

    .overlay {
        visibility: hidden;
        opacity: 0;
        background: rgba($blue, 0.95);
        text-align: center;
        transition: all .3s ease-in-out;
        overflow: hidden;

        .overlay-content {
            opacity: 0;
            position: absolute;
            top: 46%;
            width: 100%;
            font-size: 1.5rem;
            font-weight: 600;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            transition: 0.3s ease-in-out;

            i {
                display: block;
                font-size: 3.5rem;
            }
        }
    }
}