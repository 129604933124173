/* ************************************************
SITE-WIDE STYLES
************************************************ */

//  Table of Contents:
//
//   1. General
//   2. Custom Breakpoints
//   3. Custom Grid Settings
//   4. Base Typography
//   5. Typography Helpers

// 1. General
html, body {
    height: 100%;
    font-family: $font-hind;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
}

html {
    overflow-x: hidden;
}

body {
    background: #fff;
    color: $dark-text;
}

img {
    max-width: 100%;
}

a, button, i {
    transition: $transition-cubic-bezier;

    &:focus, &:active {
        outline: 0;
    }
}

.main {
    padding-top: 4rem + ($header-height + $topbar-height);
    padding-bottom: 4rem;
}

.overlay {
    position: absolute;
    background: rgba(#151515, 0.65);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}



// 2. Custom Breakpoints


// 3. Custom Grid settings
.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
}

.home {

    .page-content {
        margin-top: 0;
        padding-top: 0;
    }
}

section {
    border-bottom: 1px solid $border-light;

    &:nth-child(even) {
        background: #fff;
    }

    &:nth-child(odd) {
        background: #f9f7f5;
    }

    .container {
        padding-top: 3rem;
        padding-bottom: 3rem;

        @include media-breakpoint-up(md) {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 8rem;
            padding-bottom: 8rem;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 10rem;
            padding-bottom: 10rem;
        }
    }
}

// 4. Base Typography


// 5. Typography Helpers
