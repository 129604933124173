﻿// ====================================================================
// FORMS
// ====================================================================
// Form elements
form, .form {
	
    label {
        font-weight: 500;
    }
    	
	input[type="text"],
	input[type="email"],
	input[type="password"],
	select,
	textarea {
        background-position: center right ($input-height / 4);
        padding: .75rem;
		color: $dark-text;
		font-weight: 300;
		border: 1px solid $border-light;
        transition: all .2s ease-out;
		-webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

		&:focus {
			color: #333;
			outline: 0;
			border: 1px solid #c8c8c8;
            box-shadow: inset 0 1px 1px rgba(0,0,0,0.025),0 0 8px rgba(102,175,233,0.125);
		}

        &:disabled {
            background: #f2f2f2!important;
            color: #888!important;
			opacity: 0.55;
            cursor: not-allowed!important;
		}
        
		&.valid {
            padding-right: ($input-padding-x * 3);
            background-repeat: no-repeat;
            background-position: center right ($input-height / 4);
            background-size: ($input-height / 3) ($input-height / 3);
			background-image: $form-icon-success;
            border-color: $blue;
            transition: all .2s ease-out;
		}

        &.input-validation-error {
            padding-right: ($input-padding-x * 3);
            background-repeat: no-repeat;
            background-position: center right ($input-height / 4);
            background-size: ($input-height / 3) ($input-height / 3);
			background-image: $form-icon-danger;
            border-color: $red;
            transition: all .2s ease-out;
		}

        &::-webkit-input-placeholder {
            font-size: 11px;
            color: #999;
        }

        &:-moz-placeholder { /* Firefox 18- */
            font-size: 11px;
            color: #999;
        }

        &::-moz-placeholder { /* Firefox 19+ */
            font-size: 11px;
            color: #999;
        }

        &:-ms-input-placeholder {
            font-size: 11px;
            color: #999;
        }

		// Remove clear input icon on IE
		&::-ms-clear {
		    display: none;
		}
	}
	
	textarea {
		height: 10em;
	}

	.styled-select {
	   	width: 100%;
	   	position: relative;
	}

	.styled-select {

        select {
		    background: #fff url(../images/icons/down-arrow.png) no-repeat 98% center;
		    border: 1px solid #aaa;
		    border-radius: 0px;
		    cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover:not([disabled]),
            &:active:not([disabled]), 
            &:focus:not([disabled]) {
                border-color: #999;
                box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(102,175,233,0.6);
            }

            &.active {
                border-color: $green;
            }

        }
	}

    .field-validation-valid {
        display: none;
        transition: all .5s ease-in-out;
    }

    .field-validation-error {
        position: absolute;
        display: block;
        margin-top: -30px;
        right: 50px;
        font-size: 11px;
        color: $red;
        pointer-events: none;
    }
}